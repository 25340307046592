import { type ClassValue, clsx } from "clsx";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const pluralize = (
  count: number,
  { one, zero, multi }: { one: ReactNode; zero: ReactNode; multi: ReactNode },
) => {
  if (count === 1) return one;
  if (count === 0) return zero;
  return multi;
};

export const toArray = <T>(o: T | T[] | null | undefined): T[] =>
  Array.isArray(o) ? o
  : o == null ? []
  : [o];

export const last = <T>(arr?: T[]) => {
  if (!arr) return undefined;
  return arr[arr.length - 1];
};
